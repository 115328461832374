<template>
  <div class="record-wrap" v-loading="loading" element-loading-text="加载中...">
    <Search @searchList="refreshList" />
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="ID" label="发送ID" width="120"></el-table-column>
      <el-table-column prop="Phone" label="发送手机号" width="200"></el-table-column>
      <el-table-column label="发送时间" width="300">
        <template #default="scope">
          {{ formatTime(scope.row.CreatedAt) }}
        </template>
      </el-table-column>
      <el-table-column label="发送状态" width="200">
        <template #default="scope">
          {{ recordObj[scope.row.Status] }}{{ scope.row.Status === 'failed' ? `(${scope.row.ChannelStatus})` : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="Content" label="内容"></el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @current-change="onChangePage"
      v-model:current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import Search from './components/Search.vue'
import axios from 'axios'
import { RECORD_OBJ } from '@/config/constEnum'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'Record',
  components: { Search },
  setup() {
    const tableData = ref([])
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)

    const status = ref('all')
    const phone = ref('all')
    const loading = ref(false)

    const refreshList = (searchPhone: { phone: string; status: string }) => {
      phone.value = searchPhone.phone
      status.value = searchPhone.status
      getList()
    }

    const onChangePage = (val: any) => {
      page.value = val
      getList()
    }

    const formatTime = (val: string) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const getList = () => {
      loading.value = true
      const reqData = {
        status: status.value,
        phone: phone.value,
        size: pageSize,
        page: page.value
      }
      axios
        .get('/-/user/stat/history', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.smss
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      getList()
    })
    return {
      page,
      total,
      phone,
      status,
      loading,
      getList,
      pageSize,
      tableData,
      formatTime,
      refreshList,
      onChangePage,
      recordObj: RECORD_OBJ
    }
  }
})
</script>
<style lang="less" scoped>
.record-wrap {
  background-color: #f2f2f2;

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
