<template>
  <div class="search-wrap">
    <el-form label-width="80px" label-position="right">
      <el-row>
        <el-col :span="6">
          <el-form-item label="手机号">
            <el-input v-model="phone" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态">
            <el-select v-model="status" placeholder="请选择状态">
              <el-option v-for="(item, index) in recordList" :key="index" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" size="medium" @click="search">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script lang="ts">
import { RECORD_LIST } from '@/config/constEnum'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'Search',
  emits: ['searchList'],
  setup(props, { emit }) {
    const phone = ref('')
    const status = ref('')

    const search = () => {
      phone.value = phone.value ? phone.value : 'all'
      status.value = status.value ? status.value : 'all'
      emit('searchList', {
        phone: phone.value,
        status: status.value
      })
      console.log(phone.value, status.value, '--vv--')
    }

    return {
      phone,
      status,
      search,
      recordList: RECORD_LIST
    }
  }
})
</script>
<style lang="less" scoped>
.search-wrap {
  padding-top: 20px;
}
</style>
